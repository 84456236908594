<template>
  <div class="account_settings">
    <!-- 居中 -->
    <div class="content">
      <!-- 面包屑导航 start -->
      <bread-crumb :nav_title="nav_title" />
      <!-- 面包屑导航 end -->

      <div class="content_box">
        <!-- 侧边导航 start -->
        <nav-left :xuanzhong="7" />
        <!-- 侧边导航 end -->

        <!-- 右边内容 -->
        <div class="content_total">
          <div class="nav">
            <!-- 搜索框 -->
            <div class="sousuo">
              <span class="inputSearch" id="searchInput">
                <el-input
                  v-model="keywords"
                  class="form-control"
                  placeholder="请输入客户姓名、手机号码"
                >
                  <template slot="append">
                    <div @click="getClientList('search')">
                      <i class="el-icon-search"></i>
                      搜索
                    </div>
                  </template>
                </el-input>
              </span>
              <!-- <span class="searchTubiao" id="searchTubiao"> 搜索 </span> -->
            </div>
            <!-- 表格 -->
            <div class="tables">
              <el-table :data="tableData" @row-click="rowClick">
                <el-table-column label="客户编号" width="120">
                  <template slot-scope="scope">{{ scope.row.id }}</template>
                </el-table-column>
                <el-table-column prop="name" label="头像" width="120">
                  <template slot-scope="scope">
                    <div>
                      <el-avatar :src="scope.row.head_link"></el-avatar></div
                  ></template>
                </el-table-column>
                <el-table-column prop="mobiles" label="手机号" width="120">
                </el-table-column>
                <el-table-column label="客户姓名" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ scope.row.real_name }}</template
                  >
                </el-table-column>
                <el-table-column prop="sqnf" label="申请时间" width="120" />
                <el-table-column prop="sqxl" label="申请学位" width="120" />
              </el-table>
            </div>

            <!-- 分页器 -->
            <div>
              <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :current-page.sync="currentPage"
                @current-change="pageChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "../components/breadcrumb";
import NavLeft from "../components/NavLeft";
import { getClientList } from "@/api/financial.js";

export default {
  data() {
    return {
      nav_title: [{ name: "财务中心" }, { name: "客户统计" }],
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 0,
      keywords: "",
    };
  },
  components: {
    breadCrumb: breadCrumb,
    NavLeft,
  },
  methods: {
    getClientList(type) {
      if (type === "search") {
        this.currentPage = 1;
      }
      let param = {
        page: this.currentPage,
        search: this.keywords,
      };
      getClientList(param).then((res) => {
        if (res.code === 200) {
          let { total, list, limit } = res.data;
          this.tableData = list;
          this.total = total;
          this.pageSize = limit;
        }
      });
    },
    rowClick(row) {
      console.log("a", row.id);
      this.$router.push(`/studentDetail?id=${row.id}`);
    },
    pageChange(page) {
      this.currentPage = page;
      this.getClientList();
    },
  },
  mounted() {
    this.getClientList();
  },
};
</script>

<style lang="scss" scoped>
.account_settings {
  background: #f5f5f5;
  .content {
    width: 1200px;
    margin: 0 auto;
    .content_box {
      display: flex;
    }
    .content_total {
      display: flex;
      .nav {
        width: 990px;
        background: #ffffff;
        border-radius: 8px;
        margin-left: 10px;
      }
    }
  }
}
//搜索框
.sousuo {
  padding: 20px 20px 0 20px;
  /deep/.el-input__inner {
    background: #fafafa;
    &.el-input__inner:focus {
      border-color: #d99d66;
    }
  }
  /deep/.el-input-group__append {
    background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
    color: #935d14;
    cursor: pointer;
  }
  input {
    width: 829px;
    height: 30px;
    background: #fcfcfc;
    border-radius: 4px;
    border: 1px solid #ededed;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    padding-left: 18px;
  }
  .searchTubiao {
    width: 101px;
    height: 34px;
    line-height: 36px;
    background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
    border-radius: 0px 4px 4px 0px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #935d14;
    text-align: center;
    margin-left: -2px;
  }
}
// 表格
.tables {
  padding: 20px;
  /deep/.el-table th,
  .el-table tr {
    background-color: #f5f5f5;
    text-align: center;
  }
  /deep/.el-table td,
  .el-table th {
    text-align: center;
  }
  /deep/ .el-avatar {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  /deep/.el-table th > .cell {
    font-weight: 500;
    color: #666666;
    font-size: 12px;
  }
  /deep/ .el-table .cell {
    color: #333333;
    font-size: 12px;
  }
  .score > span {
    color: #ff8a3c;
  }
  .active > span {
    color: #f56c6c;
  }
}
// 分页器start
/deep/ .el-pagination {
  color: #999999;
  text-align: center;
  margin-top: 40px;
  .el-pager li:not(.disabled).active {
    width: 44px;
    height: 30px;
    background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
    border-radius: 4px 8px 4px 8px;
    font-size: 16px;
    color: #ffffff;
  }
  .btn-prev,
  .el-pager li {
    width: 44px;
    height: 30px;
    border-radius: 4px 8px 4px 8px;
    border: 1px solid #ededed;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }
  .btn-next {
    width: 44px;
    height: 30px;
    border-radius: 4px 8px 4px 8px;
    border: 1px solid #ededed;
    color: #cfcfcf;
  }
}
// 分页器end
</style>
